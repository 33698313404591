export const rejectMarkOption = [
    {
        label: '平台初审驳回',
        value: "00"
    },
    {
        label: '平台复核驳回',
        value: "01"
    },
    {
        label: '门店打款驳回',
        value: "02"
    },
    {
        label: '门店审核驳回',
        value: "03"
    },
    {
        label: '回收商撤回申请',
        value: "04"
    }
]

export const formItemList = [
    {
        labelName: "订单号",
        key: 'orderNo',
        type: "input",
        lableWidth: "58px",
        placeholder: "请输入查询",
    },
    {
        labelName: "背贴码",
        key: 'barCode',
        type: "input",
        lableWidth: "58px",
        placeholder: "请输入繁星码或者唯一码查询",
    },
    {
        labelName: "回收商家",
        key: 'merchantId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "询价商家",
        key: 'companyId',
        childrenKey: "storeId",
        childrenKey2: 'inspectorId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "所属区域",
        fatherKey: "companyId",
        key: 'inspectorId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '80px',
        clear: true,
        disabled: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "询价门店",
        fatherKey: "companyId",
        key: 'storeId',
        type: "selectFilterable",
        placeholder: "请选择",
        lableWidth: '72px',
        clear: true,
        disabled: true,
        option: [],
        clearFun: () => false
    },
    {
        labelName: "机型",
        key: 'phoneName',
        type: "input",
        lableWidth: "43px",
        placeholder: "请输入机型名称查询",
    },
    {
        labelName: "串号",
        key: 'imei',
        type: "input",
        lableWidth: "43px",
        placeholder: "请输入串号查询",
    },
    {
        key: 'recycleWay',
        type: 'select',
        labelName: '回收方式',
        placeholder: '请选择',
        option: [
            {
                value: '01',
                label: '以旧换新',
            },
            {
                value: '02',
                label: '仅回收',
            },
        ],
    },
    {
        key: 'isEnablePay',
        type: 'select',
        lableWidth: '98px',
        placeholder: "请选择",
        labelName: '是否线上支付',
        option: [
            {
                label: '全部',
                value: null
            },
            {
                label: '是',
                value: true
            },
            {
                label: '否',
                value: false
            }
        ],
    },
    {
        lableWidth: "72px",
        labelName: "申请时间",
        key: 'applyTime',
        type: "daterange",
    },
]

export const tabData = [
    {
        text: "门店待审核",
        value: "00",
        num: 0,
        key: "waitPNum",
    },
    {
        text: "门店待打款",
        value: "02",
        num: 0,
        key: "waitCompanyPayNum",
    },
    {
        text: "追差完成",
        value: "04",
        num: 0,
        key: "finishNum",
    },
    {
        text: "追差关闭",
        value: "G10",
        num: 0,
        key: "closeNum",
    },
]

export const tableData = {
    loading: false,
    // 当前数据
    currentData: {},
    // 表数据
    data: [],
    // 表列
};

// 表列
export const platformTableColumns = [
    {
        prop: "photoList",
        label: "旧机照片",
    },
    {
        prop: "phoneName",
        label: "机型",
    },
    {
        prop: "imei",
        label: "串号",
    },
    {
        prop: "createCompanyName",
        label: "门店商户",
    },
    {
        prop: "createStoreName",
        label: "下单门店",
    },
    {
        prop: "createStaffInfo",
        label: "下单店员",
    },
    {
        prop: "quoteMerchants",
        label: "参与报价回收商",
    },
    {
        prop: "orderState",
        label: "订单状态",
    },
    {
        prop: "applyStaffInfo",
        label: "申请人",
    },
    {
        prop: "manualQuotePrice",
        label: "回收金额(含差异基金)"
    },
    {
        prop: "items",
        label: "追差内容",
    },
    {
        prop: "arguePrice",
        label: "申请追差金额",
    },
    {
        prop: "auditFirstInfo",
        label: "审核人/审核时间",
        // 控制是否隐藏当前
        isHide: true,
    },
    {
        prop: "auditPrice",
        label: "初审通过金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "canArguePrice",
        label: "实际追差金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "auditStaffInfo",
        label: "审核人/审核时间",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "arguePrice",
        label: "审核认差金额",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "deStoreBalance",
        label: "门店待打款",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "rejectMarkText",
        label: "关闭原因",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop:'closeInfo',
        label:"操作人",
        isHide: true
    },
    // 待对接
    {
        prop: "closeActionTime",
        label: "关闭时间",
        // 控制是否隐藏当前
        isHide: true
    },
    {
        prop: "action",
        width: '200px',
        label: "操作"
    }
]

// 责任列表
export const responsibilityData = [
    {
        label: "门店责任",
        value: "C"
    },
    {
        label: "回收商责任(驳回追差)",
        value: "M"
    },
    {
        label: "双方共同责任",
        value: "T"
    },
]

//责任切换弹窗提示
export const switchResponsibility = {
    title: '切换提示',
    show: false,
    isTip: true,
    tipText: '切换选项会清空已填写的信息，是否确认继续操作？',
    newValue: '',
}


//确认扣款余额设置
export const deBalanceVoucherProps = {
    title: "确认扣款商户余额",
    show: false,
    headerMessage: "说明：请谨慎确认追差款项从商户余额扣款，并上传沟通凭证",
}

export const photoDialogProp = {
    show: false,
    photoArr: []
}


// 驳回差异
export const rejectProp = {
    show: false,
    title: '驳回差异',
    currentData: {},
    headerMessage: "说明：驳回差异默认回收商责任，需重新填写判责依据，确认驳回后将推送给回收商",
    judgements: [],
    isReset: false
}
