<template>
  <div class="box">
    <div class="header">
      <div class="title">
        <img src="../../../assets/images/icon_cydd_.png" alt="">
        <h3>差异订单</h3>
        <p>处理、查看回收商发起的差异订单，并完成打款</p>
        <!--        <p class="order_num">共有<span class="lv_fc_red" style="text-decoration: underline;">{{ num }}</span>单超5天未处理-->
        <!--        </p>-->
      </div>
      <div class="search">
        <GlobalForm :inline="true" :needBtnLoading="true" :init-data="initData" config-icon="el-icon-search" :form-item-list="formItemList"
          confirm-btn-name="查询" @handleChange="handleChange" @handleConfirm="handleConfirm"></GlobalForm>
      </div>
    </div>
    <div class="body" v-loading="tableData.loading">
      <div class="tab_action">
        <TabSelect :tab-data="tabData" border-radius="4px" @tabAction="tabAction" />
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="excelLoading"
          @click="excelClick"
          >导出Excel</el-button
        >
      </div>
      <div v-if="tableData.data.length <= 0"
        style="width: 100%; text-align: center; padding: 80px 0px; font-size: 24px; color: rgb(102, 102, 102); background: white; font-weight: bold;">
        暂无订单~
      </div>
      <div class="table" v-for="(item, index) in tableData.data" :key="index">
        <div class="table_header">
          <div class="table_header_left">
            <p class="table_index">{{ item.index }}</p>
            <p>
              订单编号：<span :id="'order-no-' + index">{{ item.orderNo }}</span>
              <img style="width: 18px;height: 18px;cursor: pointer;transform: translate(6px,3px)"
                @click="copy('order-no-' + index); $message.success('复制成功')" src="../../../assets/images/fztb.png" alt="">
            </p>
            <p>申请时间：{{ item.applyTime }}</p>
            <p>成交回收商：{{ item.manualMerchantName }}</p>
            <!--            <p v-if="item.orderNoOneline !== '&#45;&#45;'">外部订单号：{{ item.orderNoOneline }}</p>-->
            <!--            <p v-if="item.channelName !== '&#45;&#45;'" class="channel_name">{{ item.channelName }}</p>-->
            <!--            <p v-if="item.orderType === '01'" class="matrix_name">矩阵订单</p>-->
            <div class="table_header_right" v-if="params.state !== 'G10'">
              <p class="day_num" style="color: #FF687B;" v-if="params.state === '04'">差异共处理{{
                item.handleDay
              }}天</p>
              <p class="day_num" style="color: #FF687B;" v-if="item.applyAprDays > 0 && params.state !== '04'">
                已发起差异{{ item.applyAprDays }}天，请尽快处理</p>
            </div>

          </div>
          <div class="table_header_right">
            <!-- TODO线上支付 -->
            <p class="day_num" style="color: #FF687B" v-if="item.isOnlinePay == true">线上支付</p>
            <p class="day_num" style="color: #0981FF" v-else>线下支付</p>
          </div>
        </div>
        <div>
          <el-table v-if="tableData.data.length > 0" size="small"
            :header-cell-style="{ padding: 0, backgroundColor: '#F9FBFD' }" :data="[item]">
            <template v-for="item in tableColumns">
              <el-table-column :prop="item.prop" :key="item.prop" align="center" :label="item.label"
                :class-name="item.prop === 'action' ? 'action_style' : ''" :width="item.width"
                :fixed="item.prop === 'action' ? 'right' : null" v-if="!item.isHide">
                <template v-slot="{ row }">
                  <!-- 旧机照片 -->
                  <div v-if="item.prop === 'photoList'" class="photo_list">
                    <img :src="row[item.prop][0]" alt="">
                    <p @click="voucherView(row)" class="lv_fc_line">共{{ row[item.prop].length }}张</p>
                  </div>
                  <!-- 串号 -->
                  <div v-else-if="item.prop === 'imei'">
                    <p>{{ row[item.prop] }}</p>
                    <p class="lv_fc_line" @click="getFunction(row.orderNo, row.outType)">功能情况</p>
                  </div>
                  <!-- 信息 -->
                  <div v-else-if="item.prop === 'createStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.createStaffIsMajor">创建人</p>
                    <p v-else>({{ getStoreRole(row.createStaffType) }})</p>
                  </div>
                  <!-- 参与报价回收商 -->
                  <div v-else-if="item.prop === 'quoteMerchants'">
                    <p @click="openStoreList(row)" class="lv_fc_line">{{ row[item.prop] }}</p>
                  </div>
                  <!--申请人-->
                  <div v-else-if="item.prop === 'applyStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.applyStaffIsMajor">(创建人)</p>
                    <p v-else-if="row.applyStaffType === '01'">(子账号)</p>
                    <p v-else-if="row.applyStaffType === '02'">(报价师)</p>
                    <p v-else>--</p>
                  </div>
                  <!--追差内容-->
                  <div v-else-if="item.prop === 'items'">
                    <div class="lv_ycyc2">{{ row[item.prop].join(",") }}</div>
                  </div>
                  <!-- 申請追差金额 -->
                  <div v-else-if="item.prop === 'arguePrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!-- 初审人 -->
                  <div v-else-if="item.prop === 'auditFirstInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.auditFirstTime">{{ row.auditFirstTime }}</p>
                  </div>
                  <!-- 审核人 -->
                  <div v-else-if="item.prop === 'auditStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p>{{ row.auditTime || '--' }}</p>
                  </div>
                  <!--初审金额-->
                  <div v-else-if="item.prop === 'auditPrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!--实际追差金额-->
                  <div v-else-if="item.prop === 'canArguePrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!--门店打款-->
                  <div v-else-if="item.prop === 'rePayPrice'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>

                  <!--复核人-->
                  <div v-else-if="item.prop === 'auditInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.auditReAdminTime">{{ row.auditReAdminTime }}</p>
                  </div>
                  <!--门店待打款-->
                  <div v-else-if="item.prop === 'deStoreBalanceIndex'">
                    <p class="lv_fc_red">￥{{ row.rePayPrice }}</p>
                  </div>
                  <!--审核认差金额-->
                  <div v-else-if="item.prop === 'arguePriceAuditS'">
                    <p class="lv_fc_red">￥{{ row[item.prop] }}</p>
                  </div>
                  <!--撤回人-->
                  <div v-else-if="item.prop === 'cancelStaffInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.cancelStaffType">(创建人)</p>
                    <p v-else-if="row.cancelStaffType === '01'">(子账号)</p>
                    <p v-else-if="row.cancelStaffType === '02'">(报价师)</p>
                    <p v-else>--</p>
                  </div>
                  <!--追差关闭操作人-->
                  <div v-else-if="item.prop === 'closeInfo'">
                    <p>{{ row[item.prop] }}</p>
                    <p v-if="row.rejectMark === '03' || row.rejectMark === '04'">({{ row.closeRoleType }})</p>
                  </div>
                  <!-- 操作 -->
                  <div v-else-if="item.prop === 'action'" class="action_box">
                    <!--                    <p class="lv_fc_line" v-if="params.state === '02'">追差进度</p>-->
                    <!--                    <p class="lv_fc_line"-->
                    <!--                       v-if="params.state === '02' || params.state === '04' || params.state === '10'"-->
                    <!--                       @click="openAction(`/difference/process?type=check&argueNo=${row.argueNo}`)">查看详情</p>-->
                    <!--                    <p class="lv_fc_line" style="color: #FF0000" v-if="params.state === '01' || params.state === '02'"-->
                    <!--                       @click="rejectClick(row)">驳回差异</p>-->
                    <!--                    <p class="lv_fc_line" v-if="params.state === '01'"-->
                    <!--                       @click="$router.push(`/difference/process?type=check&argueNo=${row.argueNo}`)">复核打款</p>-->
                    <!--                    <p class="lv_fc_line" v-if="params.state === '00'" style="color: #FF9204"-->
                    <!--                       @click="$router.push(`/difference/process?type=toExamine&argueNo=${row.argueNo}`)">审核差异</p>-->
                    <p class="lv_fc_line" @click="openAction(`/difference/process?argueNo=${row.argueNo}&type=store`)">
                      查看详情</p>
                  </div>
                  <div v-else>
                    {{ row[item.prop] }}
                  </div>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <Pagination :meta="meta"
        @sizeChange="(v) => { meta = { ...meta, pageSize: v }; params = { ...params, pageSize: v } }"
        @pageChange="(v) => { meta = { ...meta, pageNum: v }; params = { ...params, pageNum: v } }" />
    </div>
    <PhotoPreview :photoVisible="photoDialogProp.show" :photoArr="photoDialogProp.photoArr" title="旧机照片"
      @close="() => photoDialogProp.show = false" />


    <!-- 参数报价回收商（复制代码） -->
    <el-dialog :close-on-click-modal="false" :width="`80%`" title="查看参与报价回收商" :visible="recyclerData.show"
      @close="recyclerData.show = false" top="15%">
      <div class="img-box">
        <p class="lv_fc_red" style="margin-bottom: 10px;">
          说明：若该门店商户预留差异基金，则门店所看到的报价金额需加上差异基金才是商户的初始报价</p>
        <div class="table_list">
          <el-table :data="recyclerData.storeList" border style="width: 100%" id="excelOrder" :cell-style="rowStyle"
            @cell-click="Bargain">
            <el-table-column prop="index" type="index" label="序号"></el-table-column>
            <el-table-column prop="recycleMerchantName" label="报价商家" align="center" width="200">
              <template scope="{ row }">
                <!-- <span
                  class="position isdeal"
                  v-if="row.recycleMerchantName == merchantName"
                  >成交</span
                > -->
                <el-tag v-if="row.recycleMerchantName === recyclerData.merchantName" size="mini" type="danger"
                  effect="dark" style="margin-right: 10px">
                  成交
                </el-tag>
                <span>{{ row.recycleMerchantName }}</span>
                <div>
                  <div style="background:rgb(245, 245, 255);border-radius: 4px;padding: 0px 8px;margin-top: 4px;display:inline-block" v-if="row.virtualName && row.virtualName !=='--'&&baoPricestate=='00'">{{ row.virtualName }}</div>
                </div>
                <div>
                  <el-tag v-if="row.isFixHighQuote &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixHighQuote != '--'
                    " size="mini" type="warning" effect="dark" style="margin-right: 10px">
                    首次取价
                  </el-tag>
                  <el-tag v-if="row.isFixExpPrice &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixExpPrice != '--'
                    " size="mini" type="warning" effect="dark">
                    议价取价
                  </el-tag>
                  <el-tag v-if="row.isAddPrice && row.isAddPrice != '--'
                    " size="mini" type="success" effect="dark">
                    平台加价
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="quotePrice" width="100" label="报价金额" align="center"></el-table-column>
            <el-table-column prop="exclusiveTime" width="100" label="抢单时间" align="center"></el-table-column>
            <el-table-column prop="quoteTime" width="180" label="最新报价时间" align="center"></el-table-column>
            <el-table-column prop="staffName" label="报价人" align="center"></el-table-column>
            <el-table-column prop="staffMobile" label="账号" align="center"></el-table-column>
            <el-table-column prop="staffType" label="角色" align="center"></el-table-column>
            <el-table-column prop="quoteNum" width="100" label="报价次数" align="center"></el-table-column>
            <el-table-column prop="state" width="100" label="报价状态" align="center">
              <template scope="{ row }">
                <span>{{
                  row.state == "00-0"
                  ? "待报价"
                  : row.state == "00-1"
                    ? "报价中"
                    : row.state == "00-2"
                      ? "已报价"
                      : row.state == "01"
                        ? "已确认"
                        : row.state == "10" && row.exclusiveCancelTime != "--"
                          ? `取消抢单${row.exclusiveCancelTime}`
                          : "交易取消"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isNiceDevice" width="100" label="是否标记靓机" align="center">
              <template scope="{ row }">
                <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteNote" width="100" label="报价备注" align="center">
              <template scope="{ row }">
                <el-tooltip v-if="row.quoteNote != '--'" class="item" effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote" placement="bottom">
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <!-- 新增 -->
            <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
            <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看报价记录" :visible="viewrecords"
      @close="viewrecordsClose" top="15%" bottom="10%">
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table :data="getUpdatedQuoteLog" max-height="300" border style="width: 100%" id="updatedOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="160" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
        </el-table>
        <div style="color: #0981ff; font-size: 16px; margin: 20px 0" v-if="remakeQuoteLog && remakeQuoteLog.length">
          补拍后报价记录
        </div>
        <el-table v-if="remakeQuoteLog && remakeQuoteLog.length" :data="remakeQuoteLog" max-height="300" border
          style="width: 100%" id="remakeOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="remakeTime" width="100" label="补拍时间" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="100" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                ? "创建人"
                : row.positionType == "01"
                  ? "子账号"
                  : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 新增 -->
          <el-table-column prop="originalQuotePrice" width="100" label="报价金额（含差异基金）" align="center"></el-table-column>
          <el-table-column prop="diffFundPrice" width="100" label="差异基金" align="center"></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 旧机功能情况 -->
    <el-dialog v-bind="imeiTestProps" :before-close="() => imeiTestProps.show = false" :visible.sync="imeiTestProps.show">
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :datainfo="detailData" />
        </div>
        <div>
           <!-- 估价单验机报告 -->
           <div class="test-box">
            <div class="test-result" v-if="detailData.functionView.errorList.length">
              <Title :title="'异常项（共'+ detailData.functionView.errorList.length +'项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="detailData.unableDetectionCause &&
                detailData.detectionType == '01'
                ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="detailData.detectionType == '02'">
                <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList"></TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
              <Title :title="'正常项（共'+ detailData.functionView.normalList.length +'项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList"></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0;">
          <Title title="店员备注" />
          <Remark :remark="detailData.remark" :againPhotoRemark="detailData.againPhotoRemark" />
        </div>
      </div>
      <div slot="footer">
        <el-button v-if="imeiTestProps.type === '01'" @click="imeiTestProps.show = false" plain type="primary">关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 驳回差异 -->
    <DialogAction v-bind="rejectProp" @close="rejectProp.show = false; rejectProp.isReset = false" @config="rejectConfig">
      <template v-slot:from>
        <div class="rejetct_box">
          <div class="reject_duty">
            <div style="margin-right: 10px;">审批判责：</div>
            <el-radio disabled>回收商责任（驳回追差）</el-radio>
          </div>
          <div class="reject_basis">
            <JudgementsList :source-data="rejectProp.judgements" :is-reset="rejectProp.isReset" state="00"
              @dataChange="(v) => rejectChange(v)" />
          </div>
        </div>
      </template>
    </DialogAction>
  </div>
</template>
<script>

import {
  formItemList,
  photoDialogProp,
  platformTableColumns, rejectMarkOption, rejectProp,
  tabData,
  tableData
} from "@/views/difference/storeReview/initState";
import _api from "@/utils/request";
import moment from "moment";
import {
  copy,
  getHaoTime,
  getIndex,
  getMeta, getRecoveryType,
  getRecycleState,
  getStoreRole,
  guideExcelAction,
  infoConcat, openAction,
  pageSize,
  setValue
} from "@/utils/common";
import PhotoPreview from "@/components/common/PhotoPreview.vue";
import TabSelect from "@/components/common/TabSelect.vue";
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import Pagination from "@/components/common/Pagination.vue";
import { mapState } from "vuex";
// ***********
import DialogAction from "@/components/common/DialogAction.vue";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Title from "@/views/RecallOrder/componets/Title.vue"
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import JudgementsList from "@/views/difference/componets/JudgementsList.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";

export default {
  components: {
    JudgementsList,
    DialogAction,
    Pagination,
    GlobalForm,
    TabSelect,
    PhotoPreview,
    TestTable,
    TestDeviceInfo,
    Title, Remark
  },
  computed: {
    ...mapState(['adminButton'])
  },
  data() {
    const startTime = moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD");
    const endTime = moment().endOf('day').format("YYYY-MM-DD");
    const time = [startTime, endTime];
    const { orderNo } = this.$route.query;//订单详情跳转过来携带订单号
    return {
      baoPricestate:"",//订单状态
      // 功能情况接口
      detailData: {
        functionView:{
          normalList:[],
          errorList:[],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: []
      },
      imeiTestProps,//功能情况
      tabData,
      tableData,
      // 嵌套过深，element无法检测到
      tableColumns: JSON.parse(JSON.stringify(platformTableColumns)),
      initData: {
        applyTime: time,
        // auditTime: time,
        // closeTime: time,
        orderType: null,
        outOrder: null,
        orderNo
      },
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      // 搜索参
      params: {
        pageSize,
        pageNum: 1,
        applyTime: time,
        state: '00',
        orderNo
        // auditTime: time,
        // closeTime: time,
      },
      // 搜索列表
      formItemList,
      // 旧机照片弹窗
      photoDialogProp,
      recyclerData: {
        show: false,
        storeList: [],
        merchantName: ''
      },
      // 驳回差异
      rejectProp,
      //差异单数超5天
      // num: 0,
      excelLoading: false,
      viewrecords: false,
      getUpdatedQuoteLog: [],
      remakeQuoteLog: [],
      externalorderType: ''
    }
  },
  created() {
    // 下拉
    this.getSelectList();
    // 列表
    this.getData('00');
    // this.getDataNum();
  },
  watch: {
    params(v) {
      this.getData();
    }
  },
  methods: {
    copy,
    openAction,
    // 差异单数超5天
    // getDataNum() {
    //   _api.storeSta().then(r => {
    //     this.num = r.data.unHandleNum;
    //   })
    // },
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } = this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if ((isSystem || unableDetectionCause) && functionList.length && (!v.isOneline || (v.isOneline && v.outType === 'fx'))) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType === 'fx')) {
        return this.imeiTestProps.type = '02';
      }
    },

    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let { isFinenessShow, functionList, systemTestList, photoArr, allSystemTestList } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness) ? fineness.fineness : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === 'lb' || v.outType === 'lb2') {
        functionList = functionList.filter(v => v.faultName !== '是否正常开机');
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map(item => {
          if (v.outType === 'lb' || v.outType === 'lb2') {
            item.value = item.value ?? [];
            item.value = item.value.map(v => {
              return {
                valueText: v.isNormal ? '正常' : '异常',
                isNormal: v.isNormal
              }
            })
          }
          return {
            name: item.label,
            value: item.value
          }
        })
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map(v => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause
        }
      });
      // 检测类型动态更改值的key
      const systemKeys = ['mobileNetworks', 'wlan', 'bluetooth'];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map(item => {
          item.value = v.detectionInfo[item.key] ?? '--';
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === '01') {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ")
          }
          return item
        });
      }


      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront)
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear)
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then(res => {
        this.detailData = res.data;
        this.imeiTestProps.isLoadding = false
      })
    },
    // 功能情况
    getFunction(v, outType) {
      this.externalorderType = outType
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: '',
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "",//下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      }
      // 请求详情
      this.getDetail(v);
    },
    getStoreRole,
    //设置时间戳
    setParamsTIme(params, key, startKey, endKey) {
      return {
        ...params,
        [startKey]: getHaoTime(params[key][0], 'start'),
        [endKey]: getHaoTime(params[key][1], 'end'),
        [key]: null,
      };
    },
    // time时间是否重置
    resetTime(params, key, startKey, endKey) {
      if (Array.isArray(params[key])) {
        params = this.setParamsTIme(params, key, startKey, endKey);
      } else {
        params[key] = null;
        params[startKey] = null;
        params[endKey] = null;
      }
      return params;
    },
    // 各种状态的数量设置
    setStateNum(data) {
      this.tabData = this.tabData.map(v => {
        if (data[v.key] >= 1000) {
          v.num = (data[v.key] / 1000) + 'k'
        } else {
          v.num = data[v.key];
        }
        return v;
      })
    },
    getData(state) {
      this.tableData.loading = true;
      this.tableData.data = [];
      let params = this.params;
      params = this.resetTime(params, 'applyTime', 'applyStartTime', 'applyEndTime');
      params = this.resetTime(params, 'auditTime', 'auditReAdminTimeStartTime', 'auditReAdminTimeEndTime');
      params = this.resetTime(params, 'closeTime', 'closeStartTime', 'closeEndTime');
      // 测试数据{...params,rejectMark:null}
      if (state) {
        params.state = state;
      }
      _api.storeList(params).then(r => {
        
        this.tableData.loading = false;
        const data = r.data ?? {};
        const page = data.page ?? {};
        this.setStateNum(data);
        const records = Array.isArray(page.records) ? page.records : [];
        // 分页
        this.meta = getMeta(page.total, params.pageNum, params.pageSize);
        this.tableData.data = records.map((v, index) => {
          // 下单人
          v.createStaffInfo = infoConcat(v.createStaffName, v.createStaffPhone);
          // 申请人
          v.applyStaffInfo = infoConcat(v.applyStaffName, v.applyStaffMobile);
          // 初审人
          v.auditFirstInfo = infoConcat(v.auditFirstAdminName, v.auditFirstAdminAcc);
          //复核人
          v.auditInfo = infoConcat(v.auditReAdminName, v.auditReAdminAcc);
          // 撤回人
          v.cancelStaffInfo = infoConcat(v.cancelStaffName, v.cancelStaffPhone);
          // 审核人
          v.auditStaffInfo = infoConcat(v.auditStaffName, v.auditStaffMobile);
          //关闭人
          v.rejectStaffStaffInfo = infoConcat(v.rejectStaffName, v.rejectStaffMobile);
          //追差关闭的几种情况
          if (['00', '01', '02'].indexOf(v.rejectMark) >= 0) {
            v.closeInfo = infoConcat(v.rejectAdminName, v.rejectAdminAcc);
            v.closeActionTime = v.rejectTime;
          } else if (v.rejectMark === '03') {
            v.closeInfo = infoConcat(v.rejectStaffName, v.rejectStaffMobile)
            v.closeActionTime = v.rejectTime;
            if (v.rejectStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              v.closeRoleType = this.getStoreRole(v.rejectStaffType);
            }
          } else if (v.rejectMark === '04') {
            // 撤回
            v.closeInfo = infoConcat(v.cancelStaffName || v.cancelPcStaffName, v.cancelStaffPhone || v.cancelPcStaffPhone);
            v.closeActionTime = v.cancelTime;
            v.closeRoleType = "";
            if (v.cancelStaffIsMajor) {
              v.closeRoleType = "创建人";
            } else {
              v.closeRoleType = getRecoveryType(v.cancelStaffType);
            }
          } else {
            v.closeInfo = "--";
            v.closeActionTime = "--";
          }
          // 后端不返回原因，得前端自己处理
          const rejectMarkIndex = rejectMarkOption.findIndex(item => item.value === v.rejectMark);
          if (rejectMarkIndex >= 0) {
            v.rejectMarkText = rejectMarkOption[rejectMarkIndex].label;
          } else {
            v.rejectMarkText = '--'
          }
          v = setValue(v);
          const currentIndex = getIndex(params.pageNum, params.pageSize, index);
          return {
            ...v,
            index: currentIndex >= 10 ? currentIndex : '0' + currentIndex,
            photoList: v.images.map(v => v.img),
            orderState: getRecycleState(v.orderState),
          };
        });
      }).finally(() => {
        if (typeof this.params.disuseLoding == "function") {
            this.params.disuseLoding();
          }
      })
    },
    getSelectList() {
      const data = {
        pageNum: 1,
        pageSize: 9999
      }
      // 回收商rq
      _api.listSimpleMerchantView(data).then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'merchantId');
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.merchantName,
            value: v.merchantId
          }
        })
      });
      // 询价商
      _api.getSelectList().then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'companyId');
        const data = Array.isArray(r.data) ? r.data : [];
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.name,
            value: v.id
          }
        })
      });
    },
    // 门店下拉
    getShopList(companyId) {
      _api.getStoreSelectList({ companyId }).then(r => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(v => v.key === 'storeId');
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.value,
            value: v.key
          }
        })
      })
    },
    // 区域
    getInspectorId(v) {
      _api.inspectorAreaSelect({ companyId: v }).then(r => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(v => v.key === 'inspectorId');
        this.formItemList[index].option = data.map(v => {
          return {
            value: v.staffId,
            label: (v.staffName ? v.staffName : '') + (v.areaName ? '-' + v.areaName : '')
          }
        })
      })
    },
    // 搜索变化
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(v => v.key === v1.childrenKey);
        const inspectorIdIndex = this.formItemList.findIndex(v => v.key === 'inspectorId');
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[inspectorIdIndex].disabled = false;
          this.getShopList(v2);
          this.getInspectorId(v2);
        } else {
          this.formItemList[index].disabled = true;
          this.formItemList[inspectorIdIndex].disabled = true;
        }
      }
    },
    // 搜索确定
    handleConfirm(v,cd) {
      console.log(this.params);
      this.params = {
        ...this.params,
        ...v,
        pageNum: 1,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      }
    },
    // 根据状态设置列
    setStateColumns(v) {
      const tableColumns = JSON.parse(JSON.stringify(platformTableColumns));
      // 门店待审核
      if (v === '00') {
        return this.tableColumns = tableColumns;
      }
      // 追差完成
      if (v === '04') {
        // 初核信息
        const auditStaffInfoIndex = tableColumns.findIndex(v => v.prop === 'auditStaffInfo');
        // 实际追差金额
        const canArguePriceIndex = tableColumns.findIndex(v => v.prop === 'canArguePrice');
        tableColumns[auditStaffInfoIndex].isHide = false;
        tableColumns[canArguePriceIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
      // 门店打款
      if (v === '02') {
        // 审核人/审核时间
        const infoIndex = tableColumns.findIndex(v => v.prop === 'auditStaffInfo');
        // 审核认差金额
        const arguePriceIndex = tableColumns.findIndex(v => v.prop === 'arguePrice');
        // 门店待打款
        const deStoreBalanceIndex = tableColumns.findIndex(v => v.prop === 'deStoreBalance');
        tableColumns[infoIndex].isHide = false;
        tableColumns[arguePriceIndex].isHide = false;
        tableColumns[deStoreBalanceIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
      // 追差关闭
      if (v === 'G10') {
        // 关闭原因
        const rejectCauseIndex = tableColumns.findIndex(v => v.prop === 'rejectMarkText');
        const cancelStaffInfoIndex = tableColumns.findIndex(v => v.prop === 'closeInfo');
        const closeTimeIndex = tableColumns.findIndex(v => v.prop === 'closeActionTime');
        tableColumns[rejectCauseIndex].isHide = false;
        tableColumns[cancelStaffInfoIndex].isHide = false;
        tableColumns[closeTimeIndex].isHide = false;
        return this.tableColumns = tableColumns;
      }
    },
    // 状态操作
    tabAction(v) {
      console.log(v);
      console.log(this.tabData[v].value);
      const state = this.tabData[v].value;
      this.params = {
        ...this.params,
        pageNum: 1,
        state
      }
      this.setStateColumns(state);
    },
    // 照片预览
    voucherView(v) {
      this.photoDialogProp = {
        ...this.photoDialogProp,
        show: true,
        photoArr: v.photoList
      }
    },

    //驳回差异
    rejectClick(v) {
      this.rejectProp = {
        ...this.rejectProp,
        show: true,
        judgements: [],
        currentData: v,
        isReset: true
      }
    },

    // 依据变化
    rejectChange(v) {
      this.rejectProp.judgements = v.map(v => {
        return {
          ...v,
          judgeType: '01'
        }
      })
    },

    // 驳回确定
    rejectConfig() {
      const { judgements, currentData } = this.rejectProp;
      const { argueNo } = currentData;
      const flag = judgements.every(v => v.reason && v.url);
      if (!flag || !judgements.length) {
        return this.$message.error("请填写完整追责依据信息");
      }
      _api.aprRejecy({ argueNo, judgements }).then(r => {
        this.$message.success("操作成功");
        this.getData();
        this.rejectProp.show = false;
      })
    },


    // 以下是复制代码
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 8 && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        column.property == "quoteNum"
      ) {
        // this.storeShow = false;
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            this.getUpdatedQuoteLog = res.data.normal;
            this.remakeQuoteLog = res.data.remake;
          });
      }
    },
    viewrecordsClose() {
      this.viewrecords = false;
    },
    // 报价商家弹窗
    openStoreList(e) {
      this.baoPricestate=e.state
      this.recyclerData.merchantName = e.manualMerchantName;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          this.recyclerData.storeList = res.data.map(v => setValue(v));
        }
      });
      this.recyclerData.show = true;
    },
    excelClick() {
      this.excelLoading = true;
      let params = this.params;
      params = this.resetTime(params, 'applyTime', 'applyStartTime', 'applyEndTime');
      _api.storeExcel(params).then(r => {
        guideExcelAction(r, '门店审核差异单', () => {
          this.excelLoading = false;
        })
      })
    },
  }
}
</script>
<style scoped lang="scss">
.function_box {
  max-height: 700px;
  overflow: auto;
}

.box {
  /deep/ .cell {
    line-height: 20px;
    padding: 10px;
  }

  ///deep/ .action_style .cell{
  //  visibility:visible;
  //  position: relative;
  //  z-index: 1999;
  //}
  /deep/ .photo_list img {
    width: 60px;
    height: 60px;
  }

  >.header {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 18px 20px 24px 15px;
    box-sizing: border-box;

    >.title {
      width: 100%;
      display: flex;
      align-items: center;

      >img {
        width: 30px;
        height: 30px;
        margin-right: 9px;
      }

      >h3 {
        font-size: 16px;
        font-weight: 700;
        color: #333333;
        margin-right: 8px;
        transform: translateY(-1px);
      }

      >p {
        font-size: 14px;
        font-weight: 400;
        color: #4C84FF;
      }
    }

    >.search {

      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      padding: 15px 14px 0px 14px;
      background: #F5F6FA;
      border-radius: 4px;
    }
  }
}

.box {
  >.body {
    margin-top: 15px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 14px 20px 14px 17px;
    box-sizing: border-box;

    >.table {
      margin-top: 14px;

      >.table_header {
        width: 100%;
        padding: 11px 0;
        padding-right: 10px;
        border-radius: 4px 4px 0px 0px;
        background: #E9F0FE;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.table_header_left {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #4C84FF;
          justify-content: space-between;

          >p {
            margin-right: 30px;
          }

          >.table_index {
            width: 30px;
            height: 18px;
            background: #F5B724;
            border-radius: 3px 9px 9px 3px;
            text-align: center;
            line-height: 18px;
            margin-right: 9px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        >.table_header_right {
          >.day_num {
            font-size: 12px;
            font-weight: 400;
            color: #FF687B;
          }
        }
      }

      /deep/ .el-table .el-table__header th {
        background: #F9FBFD !important;
        border-width: 0;
        border-bottom-width: 1px;
      }
    }
  }
}

.tab_action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >.excel_btn {
    height: 30px;
    cursor: pointer;
    background: #50CD85;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    align-items: center;
    padding: 0 9px;

    img {
      margin-right: 2px;
      width: 16px;
      height: 16px;
    }
  }
}

.photo_list {
  >img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
}

.channel_name {
  padding: 0 11px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #FFAF29;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #FFAF29;
  line-height: 20px;
}

.matrix_name {
  padding: 0 11px;
  height: 20px;
  background: #FFFFFF;
  border: 1px solid #21D8C2;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #21D8C2;
  line-height: 20px;
}

.action_box {
  display: flex;
  align-items: center;
  justify-content: space-around;

  >p {
    flex: 1;
    text-align: center;
  }
}


// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.rejetct_box {
  .reject_duty {
    display: flex;
    align-items: center;
  }

  .reject_basis {
    margin-top: 10px;

    /deep/ .box .list_box p {
      width: 80px;
    }
  }
}

.order_num {
  margin-left: 10px;
  background: #E4ECFD;
  border-radius: 4px;
  padding: 6px;
}


///deep/.table_header_style{
//}
</style>
